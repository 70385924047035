export const environment = {
  production: false,
  svAPIKey: "EST321",
  baseUrl: "https://staging.public.api.lafka.dev/api/v1",
  googleAppId: "",
  facebookAppId: "",
  firebase: {
    apiKey: "AIzaSyA1CsvzQ3HkkfwFOxEGS7dtwVVmDyVCtd4",
    authDomain: "estradi-staging.firebaseapp.com",
    projectId: "estradi-staging",
    storageBucket: "estradi-staging.appspot.com",
    messagingSenderId: "871668243505",
    appId: "1:871668243505:web:e3a954fd8e9610dcbd8910",
    measurementId: "G-9PYBSC8JLK",
  },
};
